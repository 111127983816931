<template>
    <div class="verify_account">
        <div class="area_content">
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <h2>Verify Your Mobile Phone</h2>
            <h5>Please check your inbox for a verification code to confirm your identity.</h5>
            <Form class="verify_form" @submit="handleVerify" v-slot="{ errors }" ref="verification-form">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label flex-start">Code sent to <a class="account-cred">{{ acountDetails.mobile_number }}</a></label>
                            <div class="field_wpr" :class="{ 'has-error': errors.verification_code }">
                                <Field autocomplete="off" name="verification_code" type="text" v-model="form.verification_code" placeholder="* * * * * *" rules="required" label="verification code" />
                            </div>
                            <ErrorMessage name="verification_code" class="text-danger" />
                        </div>
                    </div>
                    <div class="create_btn mt-3">
                        <button class="primary_btn" :disabled="accountCreateLoader || accountResendCodeLoader">
                            <template v-if="accountCreateLoader">
                                <i class="fa fa-spin fa-spinner"></i>&nbsp; Verifying
                            </template>
                            <template v-else>
                                <span>VERIFY</span><i class="fas fa-long-arrow-alt-right suffix"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </Form>
            <ul class="more_action">
                <li @click="resendPhoneVerificationCode($route.params.token)"><i class="fas fa-redo-alt" :class="{ 'fa-spin': accountResendCodeLoader }"></i>Send a new code</li>
                <li>
                    <i class="fas fa-times"></i>
                    <router-link :to="{ name: 'RegisterNewPhone', params: { token: $route.params.token } }">Try a different mobile phone</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Mobile Phone Verify',

        data () {
            return {
                form: {
                    verification_code: '',
                },
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            acountDetails (acountDetails) {
                const vm = this;

                if (process.env.VUE_APP_ENV == 'staging' || process.env.VUE_APP_ENV == 'local') {
                    vm.form.verification_code = acountDetails.phone_verification_code;
                }
            }
        },

        computed: mapState({
            accountCreateLoader: state => state.registerModule.accountCreateLoader,
            accountResendCodeLoader: state => state.registerModule.accountResendCodeLoader,
            acountDetails: state => state.registerModule.acountDetails,
        }),

        mounted () {
            const vm = this;

            vm.form.verification_code = '';
            vm.getAccountDetail(vm.$route.params.token).then((result) => {
                if (result) {
                    if (process.env.VUE_APP_ENV == 'staging' || process.env.VUE_APP_ENV == 'local') {
                        vm.form.verification_code = vm.acountDetails.phone_verification_code;
                    }
                } else {
                    const options = Helper.swalWarningOptions('Oops!', 'The account token is invalid or expired');

                    Swal.fire(options).then((ev) => {
                        if (ev.isConfirmed) {
                            vm.$router.push({ name: 'Register' });
                        }
                    });
                }
            });

            if (vm.$route.query && vm.$route.query.code) {
                vm.form.verification_code = vm.$route.query.code;

                setTimeout(function () {
                    vm.autoVerify();
                }, 1000);
            }
        },

        methods: {
            ...mapActions({
                verifyAccountPhone: 'registerModule/verifyAccountPhone',
                getAccountDetail: 'registerModule/getAccountDetail',
                resendPhoneVerificationCode: 'registerModule/resendPhoneVerificationCode',
            }),

            handleVerify (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError = setFieldError;
                params.token = vm.$route.params.token;

                if (vm.$route.query && vm.$route.query.plan) {
                    params.plan = vm.$route.query.plan;
                }

                vm.verifyAccountPhone(params).then((result) => {
                    if (result) {
                        const gist = window.gist;

                        if (gist && process.env.VUE_APP_ENV == 'production'  && result.email && !result.email.includes('@onboardme.io')) {
                            gist.identify(result.token, {
                                email: result.email,
                                name: result.first_name + ' ' + result.last_name,
                                tags: 'Thrive Interest',
                            });
                        }

                        vm.$router.push({name: 'SelectPlan', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                    } else {
                        vm.$refs['verification-form'].resetForm();
                    }
                });
            },

            autoVerify () {
                const vm = this;
                const params = vm.form;
                params.token = vm.$route.params.token;

                if (vm.$route.query && vm.$route.query.plan) {
                    params.plan = vm.$route.query.plan;
                }

                vm.verifyAccountPhone(params).then((result) => {
                    if (result) {
                        vm.$router.push({name: 'SelectPlan', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                    } else {
                        vm.$refs['verification-form'].resetForm();
                        vm.$router.push({name: 'VerifyPhone', params: { token: params.token }, query: { plan: vm.$route.query.plan }});
                    }
                });
            }
        },
    }
</script>

<style scoped>
    .verify_account {
        height: 100vh;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .verify_account .area_content {
        max-width: 500px;
        width: 100%;
        text-align: left;
    }

    .verify_account .area_content .logo {
        margin: 0 0 40px;
    }

    .verify_account .area_content .logo img {
        max-width: 200px;
        height: auto;
        width: 100%;
    }

    .verify_account h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        color: #121525;
        margin-bottom: 10px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        max-width: 350px;
    }

    .verify_account .verify_form {
        border: 1px solid #e9e9e9;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 20px 40px 45px 40px;
        margin-top: 30px;
    }

    .verify_account .verify_form .field_wpr {
        background: #fff;
    }

    .verify_account .more_action {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
    }

    .verify_account .more_action li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        cursor: pointer;
    }

    .verify_account .more_action li i {
        margin-right: 7px;
    }

    .verify_account .more_action li a {
        text-decoration: none;
        color: #5a5a5a;
    }

    .verify_account .primary_btn {
        position: relative;
        height: 50px;
        cursor: pointer;
    }

    .verify_account .primary_btn span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .verify_account .primary_btn .suffix {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    a.account-cred {
        margin: 0 5px;
        text-decoration: none;
        color: #2f7eed;
        cursor: pointer;
    }

    @media(max-width: 991px){
        .verify_account{
            position: relative;
            margin-top: -110px;
        }
    }

    @media(max-width: 767px){
        .verify_account .area_content{
            text-align: center;
        }
        .verify_account h5{
            margin: 0 auto;
        }
    }

    @media(max-width: 499px){
        .verify_account h2{
            font-size: 20px;
        }
        .verify_account .verify_form{
            padding: 20px 30px 40px 30px;
        }
    }
</style>
